import type { PresentationLanguages } from '@mentimeter/editor-schema/api-types-overrides';

export function sortAlphabeticallyAsc(wordA: string, wordB: string) {
  return wordA.toLowerCase().localeCompare(wordB.toLowerCase());
}

// language codes https://support.crowdin.com/api/language-codes/
export const FRENCH = 'fr';
export const SPANISH = 'es';
export const SWEDISH = 'sv';
export const ENGLISH = 'en';
export const ESTONIAN = 'et';
export const GERMAN = 'de';
export const POLISH = 'pl';
export const DUTCH = 'nl';
export const FINNISH = 'fi';
export const HINDI = 'hi';
export const ICELANDIC = 'is';
export const ITALIAN = 'it';
export const PORTUGUESE = 'pt';
export const PUNJABI = 'pa';
export const PORTUGUESE_BRAZIL = 'br';
export const RUSSIAN = 'ru';
export const HUNGARIAN = 'hu';
export const CROATIAN = 'hr';
export const JAPANESE = 'ja';
export const NORWEGIAN = 'no';
export const DANISH = 'da';
export const ROMANIAN = 'ro';
export const TURKISH = 'tr';
export const LITHUANIAN = 'lt';
export const INDONESIAN = 'id';
export const CHINESE_TRADITIONAL = 'zh';
export const CHINESE_SIMPLIFIED = 'zhs';
export const CZECH = 'cs';
export const UKRAINIAN = 'uk';
export const SLOVENIAN = 'sl';
export const WELSH = 'cy';
export const CATALAN = 'ca';
export const TAMIL = 'ta';
export const SERBIAN_LATIN = 'srl';
export const DEFAULT = ENGLISH;

export const LANGUAGES = [
  { code: ENGLISH, name: 'English' },
  { code: SPANISH, name: 'Spanish' },
  { code: SWEDISH, name: 'Swedish' },
  { code: FRENCH, name: 'French' },
  { code: POLISH, name: 'Polish' },
  { code: DUTCH, name: 'Dutch' },
  { code: ESTONIAN, name: 'Estonian' },
  { code: GERMAN, name: 'German' },
  { code: FINNISH, name: 'Finnish' },
  { code: HINDI, name: 'Hindi' },
  { code: ICELANDIC, name: 'Icelandic' },
  { code: ITALIAN, name: 'Italian' },
  { code: PORTUGUESE, name: 'Portuguese' },
  { code: PORTUGUESE_BRAZIL, name: 'Portuguese - Brazil' },
  { code: PUNJABI, name: 'Punjabi - India' },
  { code: RUSSIAN, name: 'Russian' },
  { code: HUNGARIAN, name: 'Hungarian' },
  { code: CROATIAN, name: 'Croatian' },
  { code: JAPANESE, name: 'Japanese' },
  { code: NORWEGIAN, name: 'Norwegian' },
  { code: DANISH, name: 'Danish' },
  { code: ROMANIAN, name: 'Romanian' },
  { code: TURKISH, name: 'Turkish' },
  { code: LITHUANIAN, name: 'Lithuanian' },
  { code: INDONESIAN, name: 'Indonesian' },
  { code: CHINESE_TRADITIONAL, name: 'Chinese - Traditional' },
  { code: CHINESE_SIMPLIFIED, name: 'Chinese - Simplified' },
  { code: CZECH, name: 'Czech' },
  { code: UKRAINIAN, name: 'Ukrainian' },
  { code: SLOVENIAN, name: 'Slovenian' },
  { code: WELSH, name: 'Welsh' },
  { code: CATALAN, name: 'Catalan' },
  { code: TAMIL, name: 'Tamil' },
  { code: SERBIAN_LATIN, name: 'Serbian - Latin' },
].sort((langA, langB) =>
  sortAlphabeticallyAsc(langA.name, langB.name),
) as Array<{ code: PresentationLanguages; name: string }>;

type Language = Record<string, any>;

export const requestLanguageFile = (language: string) => {
  let translationsImportPromise: Promise<Language>;

  switch (language) {
    case 'br': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-br" */
        './data/br.json'
      );
      break;
    }
    case 'ca': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ca" */ './data/ca.json'
      );
      break;
    }
    case 'cs': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-cs" */
        './data/cs.json'
      );
      break;
    }
    case 'cy': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-cy" */
        './data/cy.json'
      );
      break;
    }
    case 'da': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-da" */
        './data/da.json'
      );
      break;
    }
    case 'de': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-de" */
        './data/de.json'
      );
      break;
    }
    case 'es': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-es" */
        './data/es.json'
      );
      break;
    }
    case 'et': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-et" */
        './data/et.json'
      );
      break;
    }
    case 'fi': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-fi" */
        './data/fi.json'
      );
      break;
    }
    case 'fr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-fr" */
        './data/fr.json'
      );
      break;
    }
    case 'hi': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hi" */
        './data/hi.json'
      );
      break;
    }
    case 'hr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hr" */
        './data/hr.json'
      );
      break;
    }
    case 'hu': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hu" */
        './data/hu.json'
      );
      break;
    }
    case 'id': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-id" */
        './data/id.json'
      );
      break;
    }
    case 'is': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-is" */
        './data/is.json'
      );
      break;
    }
    case 'it': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-it" */
        './data/it.json'
      );
      break;
    }
    case 'ja': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ja" */
        './data/ja.json'
      );
      break;
    }
    case 'lt': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-lt" */
        './data/lt.json'
      );
      break;
    }
    case 'nl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-nl" */
        './data/nl.json'
      );
      break;
    }
    case 'no': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-no" */
        './data/no.json'
      );
      break;
    }
    case 'pa': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pa" */
        './data/pa.json'
      );
      break;
    }
    case 'pl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pl" */
        './data/pl.json'
      );
      break;
    }
    case 'pt': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pt" */
        './data/pt.json'
      );
      break;
    }
    case 'ro': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ro" */
        './data/ro.json'
      );
      break;
    }
    case 'ru': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ru" */
        './data/ru.json'
      );
      break;
    }
    case 'sl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-sl" */
        './data/sl.json'
      );
      break;
    }
    case 'srl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-srl" */
        './data/srl.json'
      );
      break;
    }
    case 'sv': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-sv" */
        './data/sv.json'
      );
      break;
    }
    case 'ta': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ta" */ './data/ta.json'
      );
      break;
    }
    case 'tr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-tr" */
        './data/tr.json'
      );
      break;
    }
    case 'uk': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-uk" */
        './data/uk.json'
      );
      break;
    }
    case 'zh': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-zh" */
        './data/zh.json'
      );
      break;
    }
    case 'zhs': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-zhs" */
        './data/zhs.json'
      );
      break;
    }
    default: {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-en" */
        './data/en.json'
      );
      break;
    }
  }

  return translationsImportPromise.then(
    // @ts-expect-error-auto TS-FIXME
    (file: { default: Language }) => file.default,
  );
};
